<script setup>
import { ref, onMounted, watch } from 'vue';
import { useStore } from "../store/store.js";

const store = useStore();
const filteredQuestion = ref(store.filteredQuestion());
// Добавим реф для хранения выбранного значения радиокнопки
const selectedOption = ref(''); // Начальное значение пустое

onMounted(() => {
  // Обновляем filteredQuestion каждый раз, когда текущий вопрос меняется.
  store.$subscribe((mutation, state) => {
    filteredQuestion.value = store.filteredQuestion();
  });
});

// Смотрим за изменениями filteredQuestion, чтобы сбросить выбранный вариант ответа
watch(filteredQuestion, () => {
  // Сбрасываем выбранный вариант ответа при изменении вопроса
  selectedOption.value = '';
});

function handleSelection(selectedOptionValue) {
  store.handleAnswer(filteredQuestion.value.id, selectedOptionValue);
  // Обновляем реф selectedOption при выборе ответа
  selectedOption.value = selectedOptionValue;
}
</script>


<template>
  <div class="flex flex-col">
    <h1 class="text-textH1color mt-[49px] ml-[30px]">
      Embody <br> Questions
    </h1>

    <img src="../assets/images/question-arrow.svg" alt="question"
         class="absolute mt-[98px] ml-[21px]">
    <transition name="fade" mode="out-in">
    <img :src="filteredQuestion.numberImage" alt="#"
         class="absolute mt-[58px] ml-[174px]"
         :key="filteredQuestion.id"
    >
    </transition>
  </div>
  <transition name="fade" mode="out-in">
    <div :key="filteredQuestion.id" class="absolute mt-[35.64px] ml-[34px] font-arial text-custom text-textColor">
      <p class="mr-10">
        {{ filteredQuestion.question }}
      </p>
      <div v-for="answer in ['A', 'B']" :key="answer" class="mt-[13px] radio-wrapper mr-[20px]">
        <input type="radio" :id="`radio-${answer}-${filteredQuestion.id}`"
               :value="answer"
               v-model="selectedOption"
               :name="`question-${filteredQuestion.id}`"
               @change="handleSelection(answer)" class="radio-input">
        <label :for="`radio-${answer}-${filteredQuestion.id}`" class="mr-5">
          {{ filteredQuestion[`answer${answer}`] }}
        </label>
      </div>

    </div>

  </transition>
  <transition name="fade" mode="out-in">
    <div class="w-full flex justify-center "
         :key="filteredQuestion.id"
         :class="{
         'mt-36 mb-[29px] pl-[29px] pr-[13px]': filteredQuestion.id === '1',
         'mt-36 mb-[84px]': filteredQuestion.id === '2',
         'mt-6 mb-[2px]': filteredQuestion.id === '3',
         'mt-36 mb-[94px]': filteredQuestion.id === '4',
         'mt-16 mb-[55px]': filteredQuestion.id === '5',
       }"
    >
      <img :src="filteredQuestion.image" alt="#"
      >
    </div>
  </transition>


</template>


<style scoped>
@font-face {
  font-family: 'Morely';
  src: url('https://welcome.embodybrand.ru/Morely.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-family: 'Morely', sans-serif;
  font-size: 36px;
  line-height: 98%;

}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.radio-wrapper {
  display: block; /* Изменено с inline-block на block */
  position: relative;
  margin-top: 13px; /* Добавлен отступ сверху для отделения элементов друг от друга */
}

.radio-input {
  opacity: 0;
  position: absolute;
}

.radio-input + label {
  position: relative;
  padding-left: 30px; /* Оставляет место для кастомной радиокнопки */
  cursor: pointer;
  display: inline-block;
  line-height: 20px; /* Высота радиокнопки */
}

.radio-input + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px; /* Ширина радиокнопки */
  height: 20px; /* Высота радиокнопки */
  border: 1px solid #846E6A; /* Граница радиокнопки */
  /*background-color: #fff; !* Фон радиокнопки *!*/
  /*box-shadow: 0 0 0 0px #000; !* Тень (если нужно) *!*/
}

.radio-input:checked + label::after {
  content: '';
  position: absolute;
  width: 12px; /* Ширина отметки */
  height: 10px; /* Высота отметки */
  left: 4px;
  top: 5px;
  background-image: url('../assets/images/answer-dot.svg'); /* Путь к вашему изображению */
  background-size: cover; /* Размер изображения */
}
</style>