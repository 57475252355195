<script setup>
import Footer from "../components/Footer.vue";
import {useStore} from "../store/store.js";

const store = useStore();
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <main class="flex-grow bg-mainBg z-10">
      <div class="flex flex-col items-center">
        <img src="../assets/images/result-embody.png" alt="embody"
             class="mt-[28px] max-w-[72px]"
        />
        <h1 class="text-center text-textH1color mt-[9px]">
          Results
        </h1>
        <img src="../assets/images/result-oval.svg" alt="result-oval"
             class="absolute mt-[65px]"
        />
      </div>
      <div class="flex justify-center items-center w-full h-full mt-[34px]">
        <div class="bg-resultBgColor drop-shadow w-[284px] h-[212px] flex flex-col items-center justify-center ">
          <!-- Общий контейнер для текста и изображений, выравнивание по левому краю -->
          <div class="flex flex-col items-start w-[241.5px]">
            <div class="flex justify-between items-center w-full">
              <p class="font-arial text-custom text-textColor">
                Больше А:
              </p>
              <!-- Изображение справа от текста "Больше А" -->
              <img src="../assets/images/result-shell.svg" alt="#">
            </div>
            <!-- Изображение непосредственно под текстом "Больше А", выравнивание по левому краю -->
            <img src="../assets/images/result-emph.svg" alt="#">
          </div>
          <!-- Содержимое результата, расположенное ниже -->
          <p class="font-arial text-custom text-textColor mt-[10px] w-[241.5px] text-left">
            {{store.resultA}}
          </p>
        </div>
      </div>
      <div class="flex justify-center mt-[42px]">
        <div>
          <img src="../assets/images/resultA-img-1.webp" alt="#"
               class="mr-[6px] w-[138px] h-[265px]"
          >
          <button
              class="bg-buttonColor text-textButtonColor text-[12px] font-montserrat rounded-md mt-[14px] flex justify-center items-center"
              style="width: 137px; height: 28px;"
              @click="store.redirectToSite()"
          >
            ТО, ЧТО МНЕ НУЖНО!
          </button>
        </div>
        <div>
          <img src="../assets/images/resultA-img-2.webp" alt="#"
               class="w-[138px] h-[265px]"
          >
          <button
              class="bg-buttonColor text-textButtonColor text-[12px] font-montserrat rounded-md mt-[14px] flex justify-center items-center"
              style="width: 137px; height: 28px;"
              @click="store.redirectToSite()"
          >
            ТО, ЧТО МНЕ НУЖНО!
          </button>
        </div>
      </div>
      <div class="flex justify-center mt-[32px]">
        <div>
          <img src="../assets/images/resultA-img-3.webp" alt="#"
               class="w-[280px] h-[269px]"
          >
          <button
              class="bg-buttonColor text-textButtonColor text-[12px] font-montserrat rounded-md mt-[14px] flex justify-center items-center"
              style="width: 282px; height: 28px;"
              @click="store.redirectToSite()"
          >
            ТО, ЧТО МНЕ НУЖНО!
          </button>
        </div>
      </div>
      <div class="flex justify-center mt-[28px] mb-[42px]">
        <img src="../assets/images/result-btm.svg" alt="#">
      </div>
    </main>
    <Footer/>
  </div>
</template>

<style scoped>
/* Применяем стили для линий */
main {
  background-image:
      repeating-linear-gradient(
          to bottom,
          transparent,
          transparent 37px, /* 40px (расстояние между линиями) - 3px (толщина линии) = 37px */
          #EACCCB 40px /* Расстояние между линиями */
      );
  background-attachment: local;
  line-height: 37px; /* Если вы используете line-height для выравнивания по линиям */
  position: relative;
  z-index: 1;
}
@font-face {
  font-family: 'Morely';
  src: url('https://welcome.embodybrand.ru/Morely.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-family: 'Morely', sans-serif;
  font-size: 36px;
  line-height: 98%;

}
</style>